import React, { useState, useContext } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { withStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { LanguageContext } from "../languages/LanguageProvider"

const GreenCheckbox = withStyles({
  root: {
    color: "#396db048",
    "&$checked": {
      color: "#396db0",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

// prettier-ignore
const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function Step2(props: any) {
  const [emailError, setEmailError] = useState(false)
  const { dictionary } = useContext(LanguageContext)

  return (
    <div className="demo__second">
      <div className="demo__processing">
        <CircularProgress size={50} />
        <p>
          <strong>{dictionary.processing}</strong>
          <br />
          {dictionary.processingLine1}
          <br />
          {dictionary.processingLine2}
        </p>
      </div>
      <div className="demo__email">
        {emailError && <p style={{ color: "red" }}>{dictionary.validMail}</p>}
        <label>{dictionary.emailText}</label>
        <OutlinedInput
          className="email-input"
          placeholder={dictionary.emailPlaceholder}
          value={props.data.email}
          name="email"
          onChange={props.onChange}
        />
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={props.data.newsletter}
              onChange={(e) =>
                props.onChange({
                  target: { name: "newsletter", value: e.target.checked },
                })
              }
              name="newsletter"
            />
          }
          label={dictionary.newsletter}
        />
      </div>
      <div className="step__btns">
        <button
          className="next"
          onClick={() => {
            if (!props.data.email.match(emailPattern)) {
              setEmailError(true)
            } else {
              setEmailError(false)
              props.next()
              props.addNewsletter()
            }
          }}
        >
          {dictionary.resultsBtn}
        </button>
      </div>
    </div>
  )
}
