import React from "react"
import "./App.css"
import "./components/index.css"
import "./media-query.css"
import Demoscreen from "./components/Demoscreen"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import LanguageProvider from "./languages/LanguageProvider"

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
      <LanguageProvider>
        <Demoscreen />
      </LanguageProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App
