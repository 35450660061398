import React, { createContext, useState } from "react"
import { languageOptions, dictionaryList } from "./index"

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: "en",
  dictionary: dictionaryList.en,
})

export default function LanguageProvider({ children }: { children: any }) {
  const defaultLanguage: any = new URLSearchParams(window.location.search).get(
    "lang"
  )
  const [userLanguage, setUserLanguage] = useState(
    defaultLanguage && languageOptions[defaultLanguage] ? defaultLanguage : "en"
  )

  const provider = {
    userLanguage,
    //@ts-ignore
    dictionary: dictionaryList[userLanguage],
    // userLanguageChange: selected => {
    //   const newLanguage = languageOptions[selected] ? selected : 'en'
    //   setUserLanguage(newLanguage);
    //   window.localStorage.setItem('rcml-lang', newLanguage);
    // }
  }

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  )
}
