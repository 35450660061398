import React, { useState, useEffect, useContext } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Page, Document } from "react-pdf"
import { pdfjs } from "react-pdf"
import ReactResizeDetector from "react-resize-detector"

// @ts-ignore
import axios from "axios"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Tooltip from "@material-ui/core/Tooltip"
import invoiceSchema from "../assets/invoices_schema.json"
import waybillSchema from "../assets/waybill_schema.json"
import { LanguageContext } from "../languages/LanguageProvider"
import _ from "lodash"
// @ts-ignore
import { ButtonGroup, Grid } from "@material-ui/core"
import exportFromJSON from "export-from-json"
import flatten from "flat"
import Tree from "./Tree"
import "./index.css"
import { Button } from "@material-ui/core"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export default function Step3(props: any) {
  const [caseData, setCaseData] = useState(props.case)
  const [boundingBoxes, setBoundingBoxes] = useState<any[]>([])
  const [value, setValue] = useState(0)
  const [timer, setTimer]: any = useState(null)
  const [pdfZoom, setPdfZoom] = useState(1)
  const [numPDFPages, setNumPDFPages] = useState(null)
  const { dictionary, userLanguage } = useContext(LanguageContext)

  const getCase = async () => {
    console.log("get case?")

    if (
      props.case?.id &&
      props.case?.documents?.length > 0 &&
      props.case?.status?.value !== "processed"
    ) {
      console.log("old status", props.case?.status?.value)
      const response = await axios.get(
        "https://api.digicust.com/dexter-demo-middleware/api/get-case/" +
        // "http://localhost:3005/api/get-case/" +
          props.case.id
      )
      // console.log("response updated", response.data.result.result)
      props.setCase(response.data)
      console.log("new status", response.data?.status?.value)
    }
  }

  useEffect(() => {
    const inter = setInterval(() => getCase(), 10000)
    setTimer(inter)

    return () => {
      clearInterval(timer)
    }
  }, [])
  useEffect(() => {
    setCaseData(props.case)
    if (props.case?.aggregated?.items) {
      props.case.documents[0].items = props.case.aggregated.items
    }
    props.case.documents[0].items = props.case.documents[0].items?.map(
      (item: any) => ({
        ...item,
        invoiceNumber: undefined,
      })
    )
    const bboxes: {
      x1?: number
      y1?: number
      x2?: number
      y2?: number
      page?: number
      key?: string
    }[] = []
    function getBoundingBoxesRecursively(obj: any, parentKey: string) {
      if (typeof obj === "object" && obj != null && obj !== undefined) {
        Object.entries(obj).map(([key, value]) => {
          if (key === "bbox" && typeof value === "object") {
            bboxes.push({ ...value, key: parentKey })
          }
          if (typeof value === "object") {
            getBoundingBoxesRecursively(value, key)
          }
        })
      }
    }
    if (props.case.documents[0]) {
      getBoundingBoxesRecursively(props.case.documents[0], "case")
    }
    setBoundingBoxes(bboxes)
    console.log(props.case.documents[0])
  }, [props.case])

  useEffect(() => {
    if (caseData.documents && props.case?.status?.value === "processed") {
      clearInterval(timer)
    }
  }, [caseData])

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }
  const a11yProps = (index: any) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    }
  }

  return (
    <div className='demo__third'>
      <div className='demo__processing'>
        {caseData.documents &&
        caseData.documents[0].documentStatus !== "finished" ? (
          <CircularProgress size={50} />
        ) : (
          <p></p>
        )}
        <p>
          <strong>
            {caseData.documents &&
            caseData.documents[0].documentStatus === "finished"
              ? dictionary.finishedText
              : `${dictionary.processText} ${caseData.documents[0].documentStatus} ...`}
          </strong>
          {caseData.documents &&
            caseData.documents[0].documentStatus !== "finished" && (
              <>
                <br />
                {dictionary.processingLine1}
                <br />
                {dictionary.processingLine2}
              </>
            )}
        </p>
      </div>
      <div className='demo__results'>
        <div className='results__table'>
          <AppBar position='static' color='transparent'>
            <Tabs
              value={value}
              indicatorColor='primary'
              textColor='primary'
              onChange={handleChange}
              scrollButtons='auto'
              variant='scrollable'
              aria-label='Extracted data'>
              {props.type === "invoice"
                ? invoiceSchema.map((item, index) => {
                    return (
                      <Tab
                        label={
                          userLanguage === "en" ? item.EnLabel : item.DeLabel
                        }
                        {...a11yProps(index)}
                      />
                    )
                  })
                : waybillSchema.map((item, index) => {
                    return (
                      <Tab
                        label={
                          userLanguage === "en" ? item.EnLabel : item.DeLabel
                        }
                        {...a11yProps(index)}
                      />
                    )
                  })}
            </Tabs>
          </AppBar>

          {props.type === "invoice"
            ? invoiceSchema.map((item, index) => {
                return (
                  <Tree
                    item={item}
                    value={value}
                    index={index}
                    caseData={caseData}
                  />
                )
              })
            : waybillSchema.map((item, index) => {
                return (
                  <Tree
                    item={item}
                    value={value}
                    index={index}
                    caseData={caseData}
                  />
                )
              })}
        </div>

        {/* <Document
          file=""
          className="result__document"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document> */}

        <Document
          className='pdfDocument'
          file={props.file}
          onLoadSuccess={({ numPages }) => {
            setNumPDFPages(numPages)
          }}>
          {[...Array(numPDFPages)].map((e, i) => (
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height }: { width: any; height: any }) => (
                <div style={{ position: "relative" }}>
                  {/* <p>{`${width}x${height}`}</p> */}
                  {width >= 356 && (
                    <div className='pdfZoomControls'>
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            setPdfZoom(pdfZoom + 0.2)
                          }}>
                          +
                        </Button>
                        <Button
                          onClick={() => {
                            setPdfZoom(pdfZoom - 0.2)
                          }}>
                          -
                        </Button>
                      </ButtonGroup>
                    </div>
                  )}
                  <div style={width >= 356 ? { zoom: pdfZoom } : { zoom: 1 }}>
                    <Page
                      width={350}
                      height={400}
                      className='pdfPage'
                      pageNumber={i + 1}>
                      {boundingBoxes
                        .filter((box) => box.page === i + 1)
                        .map((box) => {
                          // console.log(width)
                          // 395 changes
                          // 356 changes
                          const boxWidth = width >= 356 ? 350 : width - 13
                          return (
                            <div
                              style={{
                                left: (box.x1 / 2100) * boxWidth * 1.7,
                                top: (box.y1 / 2970) * 495 * 1.7,
                                width:
                                  (box.x2 / 2100) * boxWidth * 1.7 -
                                  (box.x1 / 2100) * boxWidth * 1.7,
                                height:
                                  (box.y2 / 2970) * 495 * 1.7 -
                                  (box.y1 / 2970) * 495 * 1.7,
                              }}
                              className='boundingBox'>
                              <div className='boundingBoxDescription'>
                                {box.key}
                              </div>
                            </div>
                          )
                        })}
                    </Page>
                  </div>
                </div>
              )}
            </ReactResizeDetector>
          ))}
        </Document>

        {/* <object
          // InvoicePDF +
          // "#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0"
          data={props.file}
          width={450}
          height={500}
        /> */}
      </div>
      <div className='step__btns'>
        <ButtonGroup>
          <button
            className='next'
            onClick={() =>
              exportFromJSON({
                data: caseData || {},
                fileName: "Digicust Dexter Download",
                exportType: "json",
                fields: {},
              })
            }>
            {dictionary.downloadJson}
          </button>
          <button
            className='next'
            onClick={() =>
              exportFromJSON({
                data: caseData || {},
                fileName: "Digicust Dexter Download",
                exportType: "xml",
                fields: {},
              })
            }>
            XML
          </button>
          <button
            className='next'
            onClick={() =>
              exportFromJSON({
                data: [flatten(caseData) || {}],
                fileName: "Digicust Dexter Download",
                exportType: "xls",
                fields: {},
              })
            }>
            XLS
          </button>
          <button
            className='next'
            onClick={() =>
              exportFromJSON({
                data: [flatten(caseData) || {}],
                fileName: "Digicust Dexter Download",
                exportType: "csv",
                fields: {},
              })
            }>
            CSV
          </button>
        </ButtonGroup>
        <span>Case ID: {props.case?.id || "undefined"}</span>
        <button className='next' onClick={() => props.next()}>
          {dictionary.anotherDocument}
        </button>
      </div>
    </div>
  )
}
