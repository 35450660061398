import React, { useState, useCallback, useContext } from "react"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import { useDropzone } from "react-dropzone"
import CircularProgress from "@material-ui/core/CircularProgress"
import { LanguageContext } from "../languages/LanguageProvider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginTop: 15,
      marginRight: 0,
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

export default function Step1(props: any) {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { dictionary } = useContext(LanguageContext)

  const onDrop = useCallback((acceptedFiles: Array<any>) => {
    acceptedFiles.forEach((file) => {
      props.setFile(file)

      const reader = new FileReader()

      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        props.setShowFile(binaryStr)
      }
      reader.readAsDataURL(file)
    })
  }, [])

  const goNext = async () => {
    setLoading(true)
    const res = await props.processDocument()
    if (res === "success") {
      setError(false)
      props.next()
    } else {
      setError(true)
    }
    setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: "application/pdf",
  })

  return (
    <div className="demo__first">
      {error && <p style={{ color: "red" }}>{dictionary.error}</p>}
      <label>{dictionary.kindOfDocument}</label>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={props.data.documentType}
          onChange={props.onChange}
          name="documentType"
          label="Document type"
        >
          <MenuItem value="invoice">{dictionary.invoice}</MenuItem>
          <MenuItem value="waybill">{dictionary.waybill}</MenuItem>
        </Select>
      </FormControl>

      {props.data.documentType && (
        <div {...getRootProps()} className="demo__upload">
          <label>{dictionary.upload}</label>
          <input {...getInputProps()} />
          <div className="file__box">
            {!isDragActive && props.file && <p>{props.file?.name}</p>}
            {isDragActive ? (
              <p>{dictionary.dropFiles}</p>
            ) : (
              !props.file && <p>{dictionary.dropClick}</p>
            )}
          </div>
        </div>
      )}
      {props.data.documentType && props.file && (
        <div className="step__btns">
          <button className="next" disabled={loading} onClick={() => goNext()}>
            {!loading ? (
              dictionary.processDocument
            ) : (
              <CircularProgress size={15} />
            )}
          </button>
        </div>
      )}
    </div>
  )
}
