import React, { useEffect, useState, useContext } from "react"

import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import axios from "axios"

export default function Demoscreen(props: any) {
  const [step, setStep] = useState(0)
  // const [recaptchaToken, setRecaptchaToken] = useState("")

  const [caseData, setCaseData] = useState(null)
  const [data, setData] = useState({
    documentType: "",
    email: "",
    newsletter: true,
  })
  const [file, setFile]: any = useState(null)
  const [showFile, setShowFile]: any = useState(null)

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setData({ ...data, [name]: value })
  }

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleExecuteRecaptcha = async () => {
    const token = executeRecaptcha && (await executeRecaptcha("demo_page"))
    // setRecaptchaToken(token as string)
    return token
  }

  const processDocument = async () => {
    const formdata = new FormData()
    const token: any = await handleExecuteRecaptcha()

    formdata.append("token", token)
    formdata.append("file", file)
    formdata.append("classification", data.documentType)
    formdata.append("email", data.email)
    formdata.append("newsletter", data.newsletter.toString())

    const response = await axios.post(
      "https://api.digicust.com/dexter-demo-middleware/api/process-document",
      // "http://localhost:3005/api/process-document",
      formdata,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )

    console.log("responding case data",response.data)

    setCaseData(response.data)
    return response ? "success" : "error"
  }

  const addNewsletter = async () => {
    console.log("caseid",(caseData as any)?.id)
    await axios.post(
      "https://api.digicust.com/dexter-demo-middleware/api/add-newsletter",
      // "http://localhost:3005/api/add-newsletter",
      {
        id: (caseData as any)?.id,
        email: data.email,
        newsletter: data.newsletter,
      }
    )
  }

  // for resizing the iframe in parent html
  const sendPostMessage = () => {
    let height
    const documentSelector: any = document
    if (height !== documentSelector.getElementById("demo-box").offsetHeight) {
      height = documentSelector.getElementById("demo-box").offsetHeight
      window.parent.postMessage(
        {
          frameHeight: height >= 200 ? height : 200,
        },
        "*"
      )
    }
  }

  useEffect(() => {
    sendPostMessage()
  }, [step, data, file])
  useEffect(() => {
    window.onresize = () => sendPostMessage()
  }, [])

  return (
    <div className='demo__container'>
      <div className='demo__box' id='demo-box'>
        {step === 0 && (
          <Step1
            data={data}
            onChange={(e: any) => handleChange(e)}
            file={file}
            setFile={setFile}
            setShowFile={setShowFile}
            next={() => setStep(1)}
            processDocument={() => processDocument()}
          />
        )}
        {step === 1 && (
          <Step2
            next={() => setStep(2)}
            data={data}
            onChange={(e: any) => handleChange(e)}
            addNewsletter={() => addNewsletter()}
          />
        )}
        {step === 2 && (
          <Step3
            case={caseData}
            setCase={setCaseData}
            type={data.documentType}
            file={showFile}
            next={() => setStep(0)}
          />
        )}
      </div>
    </div>
  )
}
