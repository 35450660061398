import TabPanel from "./TabPanel"
import Tooltip from "@material-ui/core/Tooltip"
import _ from "lodash"
import { Grid } from "@material-ui/core"
import React from "react"

export default function Tree({
  value,
  index,
  caseData,
  item,
}: {
  value: any
  index: any
  caseData: any
  item: any
}) {
  function removeEmptyObjects(obj: any): any {
    if (Array.isArray(obj)) {
      const objects: any = []
      obj.forEach((item) => {
        let cleanedObj = {}
        Object.keys(item).forEach((key) => {
          const value = item[key]
          const cleaned = _.pickBy(value, (v, k) => {
            if (Array.isArray(v)) {
              return v.length > 0
            }
            return !(v == null && v == undefined)
          })
          if (cleaned != null && Object.keys(cleaned).length > 0) {
            cleanedObj = { ...cleanedObj, [key]: { ...cleaned } }
          }
        })
        objects.push(cleanedObj)
      })
      return objects
    }
    return _.pickBy(obj, (v, k) => {
      if (Array.isArray(v)) {
        return v.length > 0
      }
      return !(v == null && v == undefined)
    })
  }
  return (
    <TabPanel value={value} index={index}>
      <Grid container>
        {/* @ts-ignore */}
        {item.properties.map((prop, key) => {
          const query = _.get(caseData.documents[0], prop)
          const cleaned = removeEmptyObjects(query)
          if (cleaned != null && Object.keys(cleaned).length > 0) {
            const buildItem = (content: any, key?: string): any => {
              const blacklistMetaFields = [
                "input",
                "source",
                "modifiedAt",
                "options",
                "bbox",
                "lastChangedBy",
                "txId",
                "currentQuestionInteraction",
                "caseId",
                "documentId",
                "id",
                "_rid",
                "_self",
                "_etag",
                "_attachments",
              ]
              return [
                "input",
                "source",
                "modifiedAt",
                "options",
                "bbox",
                "lastChangedBy",
                "confidence",
                "caseId",
                "documentId",
                "id",
                "_rid",
                "_self",
                "_etag",
                "_attachments",
              ].includes(key || "") ? (
                <React.Fragment></React.Fragment>
              ) : (
                <Grid container spacing={2} xs={12}>
                  {key && (
                    <Grid style={{ overflowX: "hidden" }} item xs={4}>
                      <b>{key.charAt(0).toUpperCase() + key.slice(1)}</b>
                    </Grid>
                  )}
                  <Grid item xs={key ? 8 : 12}>
                    {content &&
                    (content.formattedAddress ||
                      content.potentialHeadings ||
                      content.value ||
                      content.type ||
                      content.EUREquivalent ||
                      content.hsCode ||
                      content.input) ? (
                      <Tooltip
                        placement='right'
                        title={Object.entries(content).map(([key, value]) =>
                          blacklistMetaFields.includes(key || "") ? (
                            <React.Fragment></React.Fragment>
                          ) : (
                            <Grid container style={{ minWidth: 150 }}>
                              <Grid item xs={4}>
                                {key.charAt(0).toUpperCase() + key.slice(1)}
                              </Grid>
                              <Grid item xs={8}>
                                {(typeof value === "string" ||
                                  typeof value === "number" ||
                                  typeof value === "boolean") &&
                                  value.toString()}
                              </Grid>
                            </Grid>
                          )
                        )}>
                        <span>
                          {(content.formattedAddress ||
                            content.value ||
                            (content.potentialHeadings?.[0]?.length
                              ? content.potentialHeadings?.[0] +
                                "???".substring(
                                  0,
                                  6 -
                                    (content.potentialHeadings?.[0]?.length ||
                                      0)
                                )
                              : //.join("?")
                                false) ||
                            content.type ||
                            content.EUREquivalent ||
                            content.hsCode?.value ||
                            content.input) +
                            (content.unit
                              ? " " + content.unit || content.UOM
                              : "")}
                        </span>
                      </Tooltip>
                    ) : typeof content === "object" && content ? (
                      <React.Fragment>
                        {Object.entries(content).map(([newKey, newContent]) =>
                          buildItem(newContent, newKey)
                        )}
                      </React.Fragment>
                    ) : (
                      <span>{content?.toString()}</span>
                    )}{" "}
                  </Grid>
                </Grid>
              )
            }
            return prop === "items" ? (
              <Grid
                style={{ padding: 10, margin: 5 }}
                container
                spacing={5}
                xs={12}>
                <Grid item xs={12}>
                  {buildItem(cleaned)}
                </Grid>
              </Grid>
            ) : (
              <Grid
                style={{ padding: 10, margin: 5 }}
                container
                spacing={5}
                xs={12}>
                <Grid style={{ overflowX: "hidden" }} item xs={3}>
                  <b>{prop.charAt(0).toUpperCase() + prop.slice(1)}</b>
                </Grid>
                <Grid item xs={9}>
                  {buildItem(cleaned)}
                </Grid>
              </Grid>
            )
          }
        })}
      </Grid>
    </TabPanel>
  )
}
